$(document).on('change', '#user_name.is-invalid', function() {
  $("#user_name").removeClass('is-invalid');
  $("#user_name").next().css('display', 'none');
});

$(document).on('change', '#user_email.is-invalid', function() {
  $("#user_email").removeClass('is-invalid');
  $("#user_email").next().css('display', 'none');
});

$(document).on('change', '#user_password.is-invalid', function() {
  $("#user_password").removeClass('is-invalid');
  $("#user_password").next().css('display', 'none');

});
$(document).on('change', '#user_password_confirmation.is-invalid', function() {
  $("#user_password_confirmation").removeClass('is-invalid');
  $("#user_password_confirmation").next().css('display', 'none');
});
